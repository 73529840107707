import React, { useState, useEffect, useRef } from "react";
import socketIOClient from "socket.io-client";
const SOCKET_SERVER_URL = "https://api.nha.gov.ph";
// const SOCKET_SERVER_URL = "http://172.16.10.40:7600";

const App = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [targetEmail, setTargetEmail] = useState("");
  const socketRef = useRef();

  useEffect(() => {
    socketRef.current = socketIOClient(SOCKET_SERVER_URL, {
      transports: ["polling", "websocket"],
      withCredentials: true, // This line ensures cookies are sent with the request if needed
    });

    socketRef.current.on("message", (message) => {
      console.log("Received message:", message);
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    socketRef.current.on("connect", () => {
      console.log("Connected with socket ID:", socketRef.current.id);
    });

    socketRef.current.on("disconnect", () => {
      console.log("Disconnected");
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, []);

  const registerEmail = () => {
    socketRef.current.emit("registerEmail", email); // Emit registerEmail event
  };

  const sendMessage = () => {
    console.log(`Sending message to ${targetEmail}: ${message}`);
    socketRef.current.emit("privateMessage", {
      message,
      toEmail: targetEmail,
    });
    setMessage("");
  };

  return (
    <div>
      <h1>Instant Messaging</h1>
      <div>
        <input
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder='Your email'
        />
        <button onClick={registerEmail}>Register Email</button>
      </div>
      <div>
        {messages.map((msg, index) => (
          <p key={index}>{msg}</p>
        ))}
      </div>
      <input
        type='text'
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder='Type a message'
      />
      <input
        type='email'
        value={targetEmail}
        onChange={(e) => setTargetEmail(e.target.value)}
        placeholder='Target email'
      />
      <button onClick={sendMessage}>Send</button>
    </div>
  );
};

export default App;
